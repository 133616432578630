import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MeteoHomeTabs } from '@meteo/business/Meteo.types';
import { City } from '@meteo/types';
import { RootState } from '@store/store';
import { usePlcRouter } from '@utils/customHooks';
import { fromCityToUrlSlug, getAs, urlEnum } from '@utils/url';
import { Dayjs } from 'dayjs';

type WeatherLinkProps = {
  targetUrl: urlEnum.meteo | urlEnum.meteoRadar | urlEnum.treatmentSchedule | urlEnum.hourByHour;
  options?: { meteoHomeTab?: MeteoHomeTabs; hourByHourDay?: Dayjs };
};

export function buildWeatherLink(
  targetUrl: WeatherLinkProps['targetUrl'],
  options: WeatherLinkProps['options'] = {},
  city?: City,
  // used for SSG
  defaultLocalitySlug?: string
) {
  const { meteoHomeTab } = options;
  const locality = city ? fromCityToUrlSlug(city) : (defaultLocalitySlug as string);
  switch (targetUrl) {
    case urlEnum.treatmentSchedule:
    case urlEnum.meteoRadar: {
      const href = `${targetUrl}${locality ? `/[locality]` : ''}`;
      return {
        as: getAs(href, locality ? { locality } : {}),
        href,
      };
    }
    case urlEnum.meteo: {
      let href = null;
      if (locality) {
        href = `${targetUrl}/[type]/[locality]`;
        return {
          as: getAs(href, {
            locality,
            type: meteoHomeTab || MeteoHomeTabs.ThirtySixHours,
          }),
          href,
        };
      }
      href = `${targetUrl}/[type]`;
      return { as: getAs(href, { type: meteoHomeTab || MeteoHomeTabs.ThirtySixHours }), href };
    }
    case urlEnum.hourByHour: {
      const href = `${targetUrl}${locality ? `/[locality]` : ``}`;
      return {
        as: getAs(href, locality ? { locality } : {}),
        href,
      };
    }
    default: {
      return { as: '', href: '' };
    }
  }
}

export function useWeatherLink(targetUrl: WeatherLinkProps['targetUrl'], options?: WeatherLinkProps['options']) {
  const { currentLocation, isInit } = useSelector((state: RootState) => state.location);
  const {
    query: { locality },
  } = usePlcRouter();
  return useMemo(
    () => buildWeatherLink(targetUrl, options, currentLocation, locality as string | undefined),
    [targetUrl, options, isInit, currentLocation, locality]
  );
}
